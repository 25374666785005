import { createStore } from 'vuex';
import precosStore from './precos';
import historicoStore from './historico';

const store = createStore({
    modules: {
        historico: historicoStore,
        precos: precosStore,
    },
    state() {
        return {
            todosProdutos: null,
            page_importacoes: {},
            permissions: {},
        };
    },
    mutations: {
        setTodosProdutos(state, payload) {
            state.todosProdutos = payload;
        },
        setPageImportacoes(state, payload) {
            state.page_importacoes = payload;
        },
        setPermissions(state, payload) {
            state.permissions = payload;
        },
    },
    actions: {
        setTodosProdutos(context, payload) {
            context.commit('setTodosProdutos', payload);
        },
        setPageImportacoes(context, payload) {
            context.commit('setPageImportacoes', payload);
        },
        setPermissions(context, payload) {
            context.commit('setPermissions', payload);
        },
    },
    getters: {
        getTodosProdutos(state) {
            return state.todosProdutos;
        },
        getPageImportacoes(state) {
            return state.page_importacoes;
        },
        getPermissions(state) {
            return state.permissions;
        },
    },
});

export default store;
