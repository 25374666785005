const precosStore = {
    state() {
        return {
            precos: '',
            codigo_de_barras: '',
            validade: '',
            categoria: '',
            pedidos: [],
        };
    },
    mutations: {
        setPrecos(state, payload) {
            state.precos = payload;
        },
        setPedidos(state, payload) {
            state.pedidos = payload;
        },
        setNovoPreco(state, payload) {
            let precos = state.precos;
            precos.push(payload);
        },
        setCodigoPrecos(state, payload) {
            state.codigo_de_barras = payload;
        },
        setValidadePrecos(state, payload) {
            state.validade = payload;
        },
        deletePedido(state, payload) {
            state.precos.splice(
                state.precos.findIndex(
                    (a) =>
                        a.codigo_de_barras == payload.codigo &&
                        a.data_validade == payload.validade &&
                        a.loja == payload.loja,
                ),
                1,
            );
        },
        deleteRebaixa(state, payload) {
            state.pedidos.splice(
                state.pedidos.findIndex(
                    (a) =>
                        a.codigo_de_barras == payload.codigo &&
                        a.data_validade == payload.validade &&
                        a.loja == payload.loja,
                ),
                1,
            );
        },
        setNovoPedido(state, payload) {
            state.pedidos.push(payload);
        },
        changePedidos(state, payload) {
            state.pedidos.forEach((element) => {
                if (
                    element.sku === payload.sku &&
                    element.data_validade === payload.data_validade &&
                    element.loja === payload.loja &&
                    element.status === 'Aprovado'
                ) {
                    element.status = 'Precificado';
                }
            });
        },
    },
    actions: {
        setPrecos(context, payload) {
            context.commit('setPrecos', payload);
        },
        setPedidos(context, payload) {
            context.commit('setPedidos', payload);
        },
        setNovoPreco(context, payload) {
            context.commit('setNovoPreco', payload);
        },
        setCodigoPrecos(context, payload) {
            context.commit('setCodigoPrecos', payload);
        },
        setValidadePrecos(context, payload) {
            context.commit('setValidadePrecos', payload);
        },
        deletePedido(context, payload) {
            context.commit('deletePedido', payload);
        },
        deleteRebaixa(context, payload) {
            context.commit('deleteRebaixa', payload);
        },
        setNovoPedido(context, payload) {
            context.commit('setNovoPedido', payload);
        },
        changePedidos(context, payload) {
            context.commit('changePedidos', payload);
        },
    },
    getters: {
        getPrecos(state) {
            return state.precos;
        },

        getTodosPedidos(state) {
            return state.pedidos;
        },

        getPrecosCategoria(state) {
            let prod = state.precos;

            return prod.reduce(function (key, element) {
                key[element.categoria_2] = key[element.categoria_2] || [];
                key[element.categoria_2].push(element);
                return key;
            }, Object.create(null));
        },

        getPedidos(state) {
            let prod = state.precos;

            let filtrados = prod.filter((element) => {
                return element.status == 'Pendente';
            });

            return filtrados;
        },

        getPedidosHistorico(state) {
            let prod = state.precos;

            let filtrados = prod.filter((element) => {
                return element.status != 'Pendente';
            });

            return filtrados;
        },

        getPedidosCategoria(state) {
            let prod = state.precos;

            let filtrados = prod.filter((element) => {
                return element.status == 'Pendente';
            });

            return filtrados.reduce(function (key, element) {
                key[element.loja] = key[element.loja] || [];
                key[element.loja].push(element);
                return key;
            }, Object.create(null));
        },

        getPedidosCategoriaHistorico(state) {
            let prod = state.precos;

            let filtrados = prod.filter((element) => {
                return element.status != 'Pendente' || element.status != 'Vencido sem Aprovação';
            });

            return filtrados.reduce(function (key, element) {
                key[element.loja] = key[element.loja] || [];
                key[element.loja].push(element);
                return key;
            }, Object.create(null));
        },

        getTotalPrecos(state) {
            return state.precos.length;
        },

        getTotalComercial(state) {
            let prod = state.precos;

            let filtrados = prod.filter((element) => {
                return element.status == 'Pendente';
            });

            return filtrados.length;
        },

        getPedidoPreco(state) {
            let prod = state.precos;

            let filtrados = prod.filter((element) => {
                return element.codigo_de_barras == state.codigo_de_barras && element.data_validade == state.validade;
            });

            return filtrados[0];
        },
    },
};

export default precosStore;
